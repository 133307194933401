var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Multiple", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can upload multiple files by adding the "),
        _c("code", [_vm._v("multiple")]),
        _vm._v(" property within the component"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-upload", {
            attrs: {
              multiple: "",
              text: "Upload Multiple",
              action: "https://jsonplaceholder.typicode.com/posts/",
            },
            on: { "on-success": _vm.successUpload },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <vs-upload multiple text=\"Upload Multiple\" action=\"https://jsonplaceholder.typicode.com/posts/\" @on-success=\"successUpload\" />\n</template>\n\n<script>\nexport default {\n  methods:{\n    successUpload(){\n      this.$vs.notify({color:'success',title:'Upload Success',text:'Lorem ipsum dolor sit amet, consectetur'})\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }