var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Automatic", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can enable automatic submission with the "),
        _c("code", [_vm._v("Automatic")]),
        _vm._v(
          " property that executes the shipment by changing the value of the "
        ),
        _c("code", [_vm._v("input")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-upload", {
            attrs: {
              automatic: "",
              action: "https://jsonplaceholder.typicode.com/posts/",
            },
            on: { "on-success": _vm.successUpload },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <vs-upload automatic action=\"https://jsonplaceholder.typicode.com/posts/\" @on-success=\"successUpload\" />\n</template>\n\n<script>\nexport default {\n  methods:{\n    successUpload(){\n      this.$vs.notify({color:'success',title:'Upload Success',text:'Lorem ipsum dolor sit amet, consectetur'})\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }