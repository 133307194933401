var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can upload files to the server with the "),
        _c("code", [_vm._v("vs-upload")]),
        _vm._v(" component, the requested parameter is "),
        _c("code", [_vm._v("action")]),
        _vm._v(" which is the URL of the server"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("For the title of each tab the "),
            _c("code", [_vm._v("vs-label")]),
            _vm._v(" property is implemented in the "),
            _c("code", [_vm._v("vs-tab")]),
            _vm._v(" component"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-upload", {
            attrs: { action: "https://jsonplaceholder.typicode.com/posts/" },
            on: { "on-success": _vm.successUpload },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <vs-upload action=\"https://jsonplaceholder.typicode.com/posts/\" @on-success=\"successUpload\" />\n</template>\n\n<script>\nexport default {\n  methods:{\n    successUpload(){\n      this.$vs.notify({color:'success',title:'Upload Success',text:'Lorem ipsum dolor sit amet, consectetur'})\n    }\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }