var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "upload-demo" } },
    [_c("upload-default"), _c("upload-multiple"), _c("upload-automatic")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }